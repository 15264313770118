import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Nav, Tab } from 'react-bootstrap'
import ComponentModal from '../components/componentModal'

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
		{
			allContentfulGallery {
				edges {
					node {
						galleryType
						images {
							fluid {
								src
							}
						}
					}
				}
			}
		}`
	)

	const gallery = data.allContentfulGallery.edges;
  return (
		<Layout>
			<div className="jumbotron gallery">
					<h1>Gallery</h1>
					<p></p>
			</div>
			<div className="container image-gallery">
			<Tab.Container defaultActiveKey="all">
				<Nav variant="tabs" as="ul">
					<Nav.Item>
						<Nav.Link eventKey="all">All Photos</Nav.Link>
					</Nav.Item>
					{ gallery.map((item, key) => {
						return (
							<Nav.Item key={key}>
								<Nav.Link eventKey={item.node.galleryType}>{item.node.galleryType}</Nav.Link>
							</Nav.Item>
						)
					})}
				</Nav>
				<Tab.Content>
				<Tab.Pane eventKey="all">
					<div className="photo-gallery">
						<div className="container">
							<div className="row photos">
								{
									gallery.map(all => {
										return all.node.images.map((allel, k) => {
											return (<div className="col-sm-6 col-md-4 col-lg-3 item" key={k}><ComponentModal img={allel.fluid.src} alt="window"/></div>)
										})
									})
								}
							</div>
						</div>
					</div>
				</Tab.Pane>

				{ gallery.map((el, key) => {
					return (
						<Tab.Pane eventKey={el.node.galleryType} key={key}>
							<div className="photo-gallery">
								<div className="container">
									<div className="row photos">
											{el.node.images.map((image, imgKey) => {
												return (
													<div className="col-sm-6 col-md-4 col-lg-3 item" key={imgKey}>
														<ComponentModal img={image.fluid.src} alt="window"/>
													</div>
												)
											})}
									</div>
								</div>
							</div>
						</Tab.Pane>)
					})
				}
				</Tab.Content>
			</Tab.Container>
			</div>
		</Layout>
	)
}

export default GalleryPage;