import React from 'react'
import { Modal } from 'react-bootstrap'

const ComponentModal = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
 
    const showModal = () => {
      setIsOpen(true);
    };
  
    const hideModal = () => {
      setIsOpen(false);
    };
  
    return (
      <>
        <img onClick={showModal} className="img-fluid" src={props.img} alt={props.alt}/>
        <Modal 
          show={isOpen} 
          onHide={hideModal} 
          centered
          size="xl"
        >
          <Modal.Body><img className="img-fluid" src={props.img} /></Modal.Body>
        </Modal>
      </>
    );
  };

export default ComponentModal;